<div class="gantt-calendar-today-overlay" [style.width.px]="view.width">
  <span class="today-line" *ngIf="ganttUpper.showTodayLine"> </span>
</div>

<svg class="gantt-calendar-grid-main" [attr.width]="view.width" [attr.height]="ganttUpper.styles.headerHeight - 1">
  <g>
    <g *ngIf="view.showTimeline">
      <line
        *ngFor="let point of view.secondaryDatePoints; let i = index; trackBy: trackBy"
        [attr.x1]="(i + 1) * view.cellWidth"
        [attr.x2]="(i + 1) * view.cellWidth"
        [attr.y1]="0"
        [attr.y2]="mainHeight"
        class="secondary-line"
      ></line>
    </g>
    <g>
      <line
        *ngFor="let point of view.primaryDatePoints; let i = index; trackBy: trackBy"
        [attr.x1]="(i + 1) * view.primaryWidth"
        [attr.x2]="(i + 1) * view.primaryWidth"
        [attr.y1]="0"
        [attr.y2]="mainHeight"
        class="primary-line"
      ></line>
    </g>
  </g>
</svg>
