<div class="gantt-calendar-today-overlay" [style.width.px]="view.width">
  <span class="today-rect" [hidden]="ganttUpper.viewType !== viewTypes.day"> </span>
</div>
<svg [attr.width]="view.width" [attr.height]="ganttUpper.styles.headerHeight">
  <g>
    <text
      class="primary-text"
      [ngStyle]="point.style"
      [class.today]="point.additions?.isToday"
      [class.weekend]="point.additions?.isWeekend"
      *ngFor="let point of view.primaryDatePoints; trackBy: trackBy"
      [attr.x]="point.x"
      [attr.y]="point.y"
    >
      {{ point.text }}
    </text>
    <ng-container *ngFor="let point of view.secondaryDatePoints; trackBy: trackBy">
      <text
        class="secondary-text"
        [ngStyle]="point.style"
        [class.today]="point.additions?.isToday"
        [class.weekend]="point.additions?.isWeekend"
        [attr.x]="point.x"
        [attr.y]="point.y"
      >
        {{ point.text }}
      </text>
    </ng-container>

    <g>
      <line
        *ngFor="let point of view.primaryDatePoints; let i = index; trackBy: trackBy"
        [attr.x1]="(i + 1) * view.primaryWidth"
        [attr.x2]="(i + 1) * view.primaryWidth"
        [attr.y1]="0"
        [attr.y2]="ganttUpper.styles.headerHeight"
        class="primary-line"
      ></line>
    </g>

    <g>
      <line
        [attr.x1]="0"
        [attr.x2]="view.width"
        [attr.y1]="ganttUpper.styles.headerHeight"
        [attr.y2]="ganttUpper.styles.headerHeight"
        class="header-line"
      ></line>
    </g>
  </g>
</svg>
