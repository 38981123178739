export const config = {
    "title": "NgxGantt",
    "description": "",
    "mode": "full",
    "theme": "default",
    "locales": [
        {
            "key": "en-us",
            "name": "English"
        },
        {
            "key": "zh-cn",
            "name": "中文"
        }
    ],
    "defaultLocale": "zh-cn",
    "logoUrl": "/assets/imgs/logo.png",
    "repoUrl": "https://github.com/worktile/ngx-gantt"
};
