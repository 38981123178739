<thy-layout>
  <thy-header class="header-with-baseline">
    <ng-template #headerContent>
      <thy-button-group thySize="sm" thyType="outline-default">
        <button thyButton *ngFor="let view of views" [class.active]="view.value === selectedViewType" (click)="selectView(view.value)">
          {{ view.name }}
        </button>
      </thy-button-group>
      &nbsp; &nbsp; &nbsp;
      <thy-button thyType="primary" thySize="sm" (click)="refresh()">刷新</thy-button>
      &nbsp; &nbsp; &nbsp;
      <thy-button thyType="primary" thySize="sm" (click)="scrollToToday()">今天</thy-button>
      &nbsp; &nbsp; &nbsp;
      <thy-button thyType="primary" thySize="sm" (click)="print('gantt-example')"> ↓ 导出为图片 </thy-button>
      &nbsp; &nbsp; &nbsp;
      <span>工具栏：</span>
      <thy-switch class="baseline-switch" [(ngModel)]="isShowToolbarChecked"> </thy-switch>
      &nbsp; &nbsp; &nbsp;
      <span>基线对比：</span>
      <thy-switch class="baseline-switch" [(ngModel)]="isBaselineChecked" (ngModelChange)="switchChange()">基线对比 </thy-switch>
    </ng-template>
    <ng-template #headerOperation>
      <a href="https://github.com/worktile/ngx-gantt/blob/master/example/src/app/gantt" target="_blank">查看源码</a>
    </ng-template>
  </thy-header>
  <thy-content>
    <ngx-gantt
      #gantt
      [items]="items"
      [baselineItems]="baselineItems"
      [viewType]="viewType"
      [draggable]="true"
      [linkable]="true"
      [selectable]="true"
      [multiple]="true"
      [viewOptions]="viewOptions"
      [showToolbar]="isShowToolbarChecked"
      [toolbarOptions]="toolbarOptions"
      [loading]="loading"
      [virtualScrollEnabled]="false"
      (barClick)="barClick($event)"
      (viewChange)="viewChange($event)"
      (lineClick)="lineClick($event)"
      (dragMoved)="dragMoved($event)"
      (dragEnded)="dragEnded($event)"
      (selectedChange)="selectedChange($event)"
      (linkDragEnded)="linkDragEnded($event)"
    >
      <ngx-gantt-table
        [draggable]="true"
        [dropEnterPredicate]="dropEnterPredicate"
        (dragDropped)="onDragDropped($event)"
        (dragStarted)="onDragStarted($event)"
        (dragEnded)="onDragEnded($event)"
      >
        <ngx-gantt-column name="标题" width="160px" [showExpandIcon]="true">
          <ng-template #cell let-item="item"> {{ item.title }} </ng-template>
        </ngx-gantt-column>
        <ngx-gantt-column name="开始时间" width="200px">
          <ng-template #cell let-item="item">
            {{ item.start * 1000 | date : 'yyyy-MM-dd HH:mm' }}
          </ng-template>
        </ngx-gantt-column>
        <ngx-gantt-column name="截止时间" width="200px">
          <ng-template #cell let-item="item">
            {{ item.end * 1000 | date : 'yyyy-MM-dd HH:mm' }}
          </ng-template>
        </ngx-gantt-column>
      </ngx-gantt-table>

      <ng-template #bar let-item="item">
        <span style="color: #fff">&nbsp;&nbsp;{{ item.title }} </span>
      </ng-template>
    </ngx-gantt>
  </thy-content>
</thy-layout>
