import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { addBuiltInComponents } from '@docgeni/template';
@NgModule({
  declarations: [  ],
    providers: [  ],
    imports: [ CommonModule ],
    exports: [  ]
})
export class CustomComponentsModule {
  constructor() {
    addBuiltInComponents([]);
  }
}
