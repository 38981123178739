<thy-layout>
  <thy-header>
    <ng-template #headerContent>
      <thy-button-group thySize="sm" thyType="outline-default">
        <button thyButton *ngFor="let view of views" [class.active]="view.value === viewType" (click)="viewType = view.value">
          {{ view.name }}
        </button>
      </thy-button-group>

      &nbsp; &nbsp; &nbsp;
      <thy-button thyType="primary" thySize="sm" (click)="expandAllGroups()">
        {{ expanded ? '折叠全部分组' : '展开全部分组' }}
      </thy-button>
    </ng-template>
    <ng-template #headerOperation>
      <a href="https://github.com/worktile/ngx-gantt/blob/master/example/src/app/gantt-groups" target="_blank">查看源码</a>
    </ng-template>
  </thy-header>
  <thy-content>
    <ngx-gantt
      #gantt
      [groups]="groups"
      [items]="items"
      [viewType]="viewType"
      [draggable]="true"
      [async]="true"
      [childrenResolve]="childrenResolve"
    >
      <ngx-gantt-table [draggable]="true">
        <ngx-gantt-column name="标题" width="180px">
          <ng-template #cell let-item="item"> {{ item.title }} </ng-template>
        </ngx-gantt-column>
        <ngx-gantt-column name="开始时间" width="140px">
          <ng-template #cell let-item="item">
            {{ item.start * 1000 | date : 'yyyy-MM-dd' }}
          </ng-template>
        </ngx-gantt-column>
        <ngx-gantt-column name="截止时间" width="140px">
          <ng-template #cell let-item="item">
            {{ item.end * 1000 | date : 'yyyy-MM-dd' }}
          </ng-template>
        </ngx-gantt-column>
      </ngx-gantt-table>
      <!-- group template -->
      <ng-template #group let-group="group" let-items="items">
        <span class="text-primary">{{ group.title }} 🚀 </span>
      </ng-template>
    </ngx-gantt>
  </thy-content>
</thy-layout>
