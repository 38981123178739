<ngx-gantt-root #ganttRoot>
  <div class="gantt-header">
    <gantt-table-header #tableHeader [columns]="columns"></gantt-table-header>
    <div class="gantt-container-header">
      <gantt-calendar-header [style.padding-right.px]="ganttRoot.verticalScrollbarWidth"></gantt-calendar-header>
    </div>
  </div>
  <gantt-loader *ngIf="loading"></gantt-loader>

  <cdk-virtual-scroll-viewport
    class="gantt-virtual-scroll-viewport"
    [ngClass]="{
      'gantt-normal-viewport': !virtualScrollEnabled,
      'gantt-scroll-container': virtualScrollEnabled,
      'with-footer': table?.tableFooterTemplate || footerTemplate
    }"
    [style.top.px]="styles.headerHeight"
    [itemSize]="styles.lineHeight"
    [minBufferPx]="styles.lineHeight * 10"
    [maxBufferPx]="styles.lineHeight * 20"
    (scrolledIndexChange)="scrolledIndexChange($event)"
  >
    <ng-container *cdkVirtualFor="let item of flatItems; trackBy: trackBy"></ng-container>
    <div class="gantt-side" [style.width.px]="tableHeader.tableWidth + 1" [style.padding-bottom.px]="ganttRoot.horizontalScrollbarHeight">
      <div class="gantt-side-container">
        <div class="gantt-table">
          <gantt-table-body
            [flatItems]="flatItems"
            [viewportItems]="viewportItems"
            [columns]="columns"
            [groupTemplate]="groupTemplate"
            [emptyTemplate]="table.tableEmptyTemplate || tableEmptyTemplate"
            [rowBeforeTemplate]="table?.rowBeforeTemplate"
            [rowAfterTemplate]="table?.rowAfterTemplate"
            [draggable]="table.draggable"
            [dropEnterPredicate]="table.dropEnterPredicate"
            (dragDropped)="table.dragDropped.emit($event)"
            (dragStarted)="itemDragStarted($event)"
            (dragEnded)="itemDragEnded($event)"
            (itemClick)="selectItem($event)"
          >
          </gantt-table-body>
        </div>
      </div>
    </div>
    <div class="gantt-container">
      <gantt-calendar-grid
        [style.padding-right.px]="ganttRoot.verticalScrollbarWidth"
        [style.padding-bottom.px]="ganttRoot.horizontalScrollbarHeight"
      ></gantt-calendar-grid>
      <div class="gantt-main">
        <gantt-main
          [flatItems]="flatItems"
          [viewportItems]="viewportItems"
          [groupHeaderTemplate]="groupHeaderTemplate"
          [itemTemplate]="itemTemplate"
          [barTemplate]="barTemplate"
          [rangeTemplate]="rangeTemplate"
          [baselineTemplate]="baselineTemplate"
          (barClick)="barClick.emit($event)"
          (lineClick)="lineClick.emit($event)"
        >
        </gantt-main>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>

  <gantt-drag-backdrop [style.left.px]="tableHeader.tableWidth + 1"></gantt-drag-backdrop>

  <div
    class="gantt-scrollbar"
    [style.height.px]="ganttRoot.horizontalScrollbarHeight + 1"
    [style.right.px]="ganttRoot.verticalScrollbarWidth"
  >
    <div
      [style.width.px]="tableHeader.tableWidth"
      class="gantt-table-scrollbar"
      [class.with-scrollbar]="ganttRoot.horizontalScrollbarHeight"
    ></div>
    <div class="gantt-main-scrollbar">
      <div class="h-100" [style.width.px]="view.width"></div>
    </div>
  </div>

  <div class="gantt-footer" [style.right.px]="ganttRoot.verticalScrollbarWidth" [style.bottom.px]="ganttRoot.horizontalScrollbarHeight">
    <div class="gantt-table-footer" [style.width.px]="tableHeader.tableWidth + 1" *ngIf="table?.tableFooterTemplate">
      <ng-template [ngTemplateOutlet]="table?.tableFooterTemplate" [ngTemplateOutletContext]="{ columns: columns }"> </ng-template>
    </div>
    <div class="gantt-container-footer" *ngIf="footerTemplate">
      <ng-template [ngTemplateOutlet]="footerTemplate"> </ng-template>
    </div>
  </div>
</ngx-gantt-root>
