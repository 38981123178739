<div class="gantt-demo-header">
  <div class="header-section">
    <span class="header-section-title">视图：</span>
    <span class="header-section-content">
      <input id="day" type="radio" value="day" name="view-type" [(ngModel)]="options.viewType" /> <label for="day">日</label>
      <input id="month" type="radio" value="month" name="view-type" [(ngModel)]="options.viewType" /> <label for="month">月</label>
      <input id="quarter" type="radio" value="quarter" name="view-type" [(ngModel)]="options.viewType" /> <label for="quarter">季度</label>
    </span>
  </div>
  <div class="header-section">
    <span class="header-section-title">是否可拖拽：</span>
    <span class="header-section-content">
      <input type="checkbox" [(ngModel)]="options.draggable" />
    </span>
  </div>

  <div class="header-section">
    <span class="header-section-content">
      <button (click)="gantt.expandAll()">全部展开</button>
    </span>
  </div>
  <div class="header-section">
    <span class="header-section-content">
      <button (click)="gantt.collapseAll()">全部收起</button>
    </span>
  </div>
  <div class="header-section">
    <span class="header-section-content">
      <button (click)="print('gantt-range')">导出为图片</button>
    </span>
  </div>
</div>
<div class="gantt-demo-content">
  <ngx-gantt
    #gantt
    start="1514736000"
    end="1609430400"
    [items]="items"
    [viewType]="options.viewType"
    [draggable]="options.draggable"
    [async]="options.async"
    [childrenResolve]="options.childrenResolve"
    (barClick)="barClick($event)"
    (dragEnded)="dragEnded($event)"
    (loadOnScroll)="loadOnScroll($event)"
  >
    <ngx-gantt-table>
      <ngx-gantt-column name="标题">
        <ng-template #cell let-item="item">
          {{ item.title }}
        </ng-template>
      </ngx-gantt-column>
      <ngx-gantt-column>
        <ng-template #header> <span style="font-weight: bold;">开始时间</span> </ng-template>
        <ng-template #cell let-item="item">
          {{ item.start * 1000 | date: 'yyyy-MM-dd' }}
        </ng-template>
      </ngx-gantt-column>
      <ngx-gantt-column name="截止时间">
        <ng-template #cell let-item="item">
          {{ item.end * 1000 | date: 'yyyy-MM-dd' }}
        </ng-template>
      </ngx-gantt-column>
    </ngx-gantt-table>
  </ngx-gantt>
</div>
