<thy-layout>
  <thy-header class="header-with-baseline">
    <ng-template #headerContent>
      <span class="text-primary">NgxGantt</span>
      &nbsp;&nbsp;默认开启了虚拟滚动功能，如需关闭该功能设置 virtualScrollEnabled 参数为 false 即可。
    </ng-template>
  </thy-header>
  <thy-content>
    <ngx-gantt
      #gantt
      [items]="items"
      [virtualScrollEnabled]="true"
      (virtualScrolledIndexChange)="virtualScrolledIndexChange($event)"
      [loading]="loading"
    >
      <ngx-gantt-table
        [draggable]="true"
        [dropEnterPredicate]="dropEnterPredicate"
        (dragDropped)="onDragDropped($event)"
        (dragStarted)="onDragStarted($event)"
        (dragEnded)="onDragEnded($event)"
      >
        <ngx-gantt-column name="标题" width="180px">
          <ng-template #cell let-item="item"> {{ item.title }} </ng-template>
        </ngx-gantt-column>
        <ngx-gantt-column name="开始时间" [class.start-time]="true" [width]="140">
          <ng-template #cell let-item="item">
            {{ item.start * 1000 | date : 'yyyy-MM-dd' }}
          </ng-template>
        </ngx-gantt-column>
        <ngx-gantt-column name="截止时间">
          <ng-template #cell let-item="item">
            {{ item.end * 1000 | date : 'yyyy-MM-dd' }}
          </ng-template>
        </ngx-gantt-column>

        <ng-template #tableFooter let-columns="columns">
          <div class="table-footer">
            <div class="gantt-table-column" *ngFor="let column of columns; let i = index" [style.width]="column.columnWidth">
              {{ column.name }}
            </div>
          </div>
        </ng-template>
      </ngx-gantt-table>

      <ng-template #bar let-item="item">
        <span style="color: #fff">&nbsp;&nbsp;{{ item.title }} </span>
      </ng-template>

      <ng-template #footer>
        <div class="footer-container" [style.width.px]="gantt?.view.width">
          <div
            class="footer-item"
            *ngFor="let day of gantt?.view.secondaryDatePoints"
            [style.left.px]="day.x - gantt?.view.options.cellWidth / 2"
            [style.width.px]="gantt?.view.options.cellWidth"
          >
            <ng-container *ngIf="false; else empty">
              <div class="stat stat-fill"></div>
            </ng-container>
            <ng-template #empty>
              <div class="stat stat-empty">0</div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </ngx-gantt>
  </thy-content>
</thy-layout>
