<div style="line-height: 30px; padding: 15px; display: flex;">
  <div style="flex: 1;">
    <span class="text-primary">NgxGantt</span> 导出了 <span class="text-primary">ngx-gantt-root</span>、<span class="text-primary"
      >ngx-gantt-bar</span
    >
    等基础组件，结合这些基础组件使用者可以结合自己的业务场景来封装自己的 Gantt 组件。
    <br />
    下面示例实现了任务分组的展示，并且将每个分组下任务按时间维度打平展示。
  </div>
  <div style="padding-right: 15px;">
    <a href="https://github.com/worktile/ngx-gantt/blob/master/example/src/app/gantt-advanced" target="_blank">查看源码</a>
  </div>
</div>
<thy-layout>
  <thy-content>
    <app-gantt-flat
      #gantt
      [items]="items"
      [groups]="groups"
      [viewType]="options.viewType"
      [draggable]="options.draggable"
      [styles]="options.styles"
    >
      <ng-template #group let-group="group">
        {{ group.title }}
      </ng-template>
      <ng-template #bar let-item="item">
        <span style="color: #fff">&nbsp;&nbsp;{{ item.title }}</span>
      </ng-template>
    </app-gantt-flat>
  </thy-content>
</thy-layout>
